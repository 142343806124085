import React, { Component } from 'react';

import { ApplicationResponse } from '../../api';
import { withApiData } from '../../lib/with-api-data';
import ApplicationLogsWarning from '../ApplicationLogsWarning';
import MetricGraph from '../MetricGraph';
import NoItemsFoundBlock from '../NoItemsFoundBlock';
import SortableTable, { Columns } from '../SortableTable';

import Header, { TypeFilter } from './Header';
import Level from './Level';
import Location from './Location';
import { parseLogLine, ERROR_TYPES, PhpErrorTypeCode } from './php-log';
import Timestamp from './Timestamp';
import { Filter, PhpLogEntry, TimeProps, getRepoUrl } from './util';

import './PHP.css';

const COLUMNS: Columns<PhpLogEntry> = {
	date: {
		title: 'Date (Local Time)',
		className: 'ApplicationLogs-PHP__date',
	},
	message: {
		title: 'Message',
		className: 'ApplicationLogs-PHP__message',
	},
	location: {
		title: 'Location',
		className: 'ApplicationLogs-PHP__location',
	},
};

const statusLevelMap = {
	E_ERROR: 'error',
	E_RECOVERABLE_ERROR: 'error',
	E_PARSE: 'error',

	E_USER_WARNING: 'warning',

	E_NOTICE: 'notice',
	E_STRICT: 'notice',
	E_DEPRECATED: 'notice',

	__LOG: 'log',
	__UNKNOWN: 'unknown',
};
type LogLevelProps = {
	level: PhpErrorTypeCode,
}
function LogLevel( props: LogLevelProps ) {
	return (
		<Level
			level={ statusLevelMap[ props.level ] as React.ComponentProps<typeof Level>['level'] }
			text={ ERROR_TYPES[ props.level ] }
			title={ props.level }
		/>
	);
}

interface LogTableProps {
	application: ApplicationResponse,
	data: PhpLogEntry[],
	rootUrl: string,
	types: TypeFilter,
	onOpenIssue?( item: any ): void,
	onOpenTicket?( item: any ): void,
}

function LogTable( props: LogTableProps ) {
	const parsed = props.data.map( item => ( {
		...item,
		parsed: parseLogLine( item.message ),
	} ) );
	const filtered = parsed.filter( item => {
		const type = item.parsed.type === 'log' ? 'log' : statusLevelMap[ item.parsed.detail.type ];
		if ( ! Object.hasOwn( props.types, type ) ) {
			return true;
		}

		return props.types[ type as keyof typeof props.types ];
	} );
	if ( ! filtered.length ) {
		return (
			<NoItemsFoundBlock
				message="🌴 Observe a beautiful, blank, empty paradise, free of distraction, worries of the world, and log entries. (Zero results found.)"
			/>
		);
	}
	return (
		<SortableTable<typeof parsed[0]>
			className="ApplicationLogs-PHP__table"
			columns={ COLUMNS }
			data={ filtered }
			defaultReverse
			defaultSort="date"
			isDense
			isFixed
		>
			{ item => (
				<tr
					key={ item.id }
				>
					<td className="ApplicationLogs-PHP__date">
						<div className="ApplicationLogs-PHP__date-wrap">
							<Timestamp
								date={ new Date( item.date ) }
							/>

							<LogLevel
								level={ item.parsed.type === 'error' ? item.parsed.detail.type : '__LOG' }
							/>
						</div>
					</td>
					<td className="ApplicationLogs-PHP__message">
						{ item.parsed.type === 'error' ? item.parsed.detail.message : item.parsed.detail }
					</td>
					<td className="ApplicationLogs-PHP__location">
						{ item.parsed.type === 'error' && (
							<Location
								file={ item.parsed.detail.file }
								line={ item.parsed.detail.line }
								maxLength={ 40 }
								root="/usr/src/app"
								rootUrl={ props.rootUrl }
							/>
						) }
					</td>
				</tr>
			) }
		</SortableTable>
	);
}

type ConnectedLogsProps = Filter & {
	application: ApplicationResponse,
	rootUrl: string
	types: TypeFilter,
};
type ConnectedLogsData = {
	logs: PhpLogEntry[],
};
const logConnect = withApiData<ConnectedLogsData, ConnectedLogsProps>(
	props => ( {
		logs: `/stack/applications/${ props.application.id }/logs/php?after=${ props.after }&before=${ props.before }&search=${ props.search }`,
	} )
);
const ConnectedLogs = logConnect( props => (
	<ApplicationLogsWarning
		logs={ props.logs }
		renderLogs={ ( logs: PhpLogEntry[] ) => (
			<LogTable
				application={ props.application }
				data={ logs }
				rootUrl={ props.rootUrl }
				types={ props.types }
			/>
		) }
	/>
) );

type AllProps = TimeProps & {
	application: ApplicationResponse,
	onTimeUpdate( t: any ): void,
};

type State = Filter & {
	types: TypeFilter,
};

export default class PHPLogs extends Component<AllProps, State> {
	apiData: typeof ConnectedLogs['prototype'] | null = null;

	constructor( props: AllProps ) {
		super( props );
		this.state = {
			after: this.props.after || '24 hours ago',
			before: this.props.before || 'now',
			search: '',
			types: {
				error: true,
				warning: true,
				notice: true,
				log: true,
			},
		};
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( this.state );
		}
	}

	componentWillUnmount() {
		if ( this.apiData ) {
			this.apiData?.onInvalidateData();
		}
	}

	onChangeFilter = ( filter: Filter ) => {
		this.apiData?.onInvalidateData();
		this.setState( { ...filter } );
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( filter );
		}
	};

	onRefresh = () => {
		this.apiData?.onInvalidateData();
	};

	render() {
		const repo = getRepoUrl( this.props.application['git-deployment'].url );
		return (
			<div className="ApplicationLogs-PHP PHPLog">
				<div className="metrics">
					<MetricGraph
						application={ this.props.application.id }
						domain={ {
							y: [ 0, 50 ],
							x: [ new Date( new Date().getTime() - 60 * 60 * 24 * 1000 ).getTime(), new Date().getTime() ],
						} }
						from="1 day ago"
						height={ 80 }
						label="Warnings Last 24 Hours"
						name="PHP-Warnings"
						namespace={ this.props.application.id }
						period={ 3600 }
						statistic="Sum"
						unitLabel=" warnings"
					/>
					<MetricGraph
						application={ this.props.application.id }
						domain={ {
							y: [ 0, 5 ],
							x: [ new Date( new Date().getTime() - 60 * 60 * 24 * 1000 ).getTime(), new Date().getTime() ],
						} }
						from="1 day ago"
						height={ 80 }
						label="Fatals Last 24 Hours"
						name="PHP-Fatals"
						namespace={ this.props.application.id }
						period={ 3600 }
						statistic="Sum"
						unitLabel=" fatals"
					/>
				</div>
				<Header
					after={ this.state.after }
					before={ this.state.before }
					search={ this.state.search }
					types={ this.state.types }
					onChangeFilter={ this.onChangeFilter }
					onRefresh={ this.onRefresh }
					onToggleType={ types => this.setState( { types } ) }
				/>
				<ConnectedLogs
					// @ts-ignore
					ref={ ref => this.apiData = ref }
					after={ this.state.after }
					application={ this.props.application }
					before={ this.state.before }
					rootUrl={ `https://github.com/${repo}/blob/${this.props.application['git-deployment'].ref}` }
					search={ this.state.search }
					types={ this.state.types }
				/>
			</div>
		);
	}
}
