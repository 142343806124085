import React from 'react';

import { PlainFormattedDateTime } from '../FormattedDateTime';

interface Props {
	date: Date,
}

function Timestamp( props: Props ) {
	return (
		<PlainFormattedDateTime
			className="ApplicationLogs-Timestamp"
			format="short-precise"
			value={ props.date }
		/>
	);
}

export default Timestamp;
