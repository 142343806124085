import { Action } from '../actions/fetchApplications';

export default function regionsLoading(
	state = {
		'us-east-1': false,
		'eu-west-1': false,
		'eu-west-2': false,
		'eu-central-1': false,
		'ap-southeast-1': false,
		'ap-southeast-2': false,
	},
	action: Action
) {
	switch ( action.type ) {
		case 'UPDATING_APPLICATIONS':
			return {
				...state,
				'eu-west-1': true,
			};
		case 'UPDATED_APPLICATIONS':
			return {
				...state,
				'eu-west-1': false,
			};
		default:
			return state;
	}
}
