import qs from 'qs';
import React, { useState } from 'react';

import { ApplicationResponse, cookieAuthApi, defaultAPI } from '../../api';
import { withApiData } from '../../lib/with-api-data';
import Button from '../Button';
import DurationSelector, { DurationFilter, ManualTimeFilter, TimeFilter } from '../DurationSelector';

type Props = {
	application: ApplicationResponse;
	before?: string;
	after?: string;
	onTimeUpdate( t: any ): void;
};

const FIELDS = {
	'x-edge-location': 'Edge Location (x-edge-location)',
	'sc-bytes': 'Response Size (sc-bytes)',
	'c-ip': 'Client IP (c-ip)',
	'cs-method': 'HTTP Method (cs-method)',
	'cs-host': 'Host (cs(Host))',
	'cs-uri-stem': 'URI Path (cs-uri-stem)',
	'sc-status': 'HTTP Status (sc-status)',
	'cs-referer': 'Referrer (cs(Referer))',
	'cs-user-agent': 'User Agent (cs(User-Agent))',
	'cs-uri-query': 'Query String (cs-uri-query)',
	'cs-cookie': 'Cookie (cs(Cookie))',
	'x-edge-result-type': 'Edge Result Type (x-edge-result-type)',
	'x-edge-request-id': 'Request ID (x-edge-request-id)',
	'x-host-header': 'Host Header (x-host-header)',
	'cs-protocol': 'Protocol (cs-protocol)',
	'cs-bytes': 'Request Size (cs-bytes)',
	'time-taken': 'Request Duration (time-taken)',
	'x-forwarded-for': 'Forwarded For (x-forwarded-for)',
	'ssl-protocol': 'SSL Protocol (ssl-protocol)',
	'ssl-cipher': 'SSL Cipher (ssl-cipher)',
	'x-edge-response-result-type': 'Response Result Type (x-edge-response-result-type)',
	'cs-protocol-version': 'Protocol Version (cs-protocol-version)',
	'c-port': 'Client Port (c-port)',
	'time-to-first-byte': 'Time to First Byte (time-to-first-byte)',
	'x-edge-detailed-result-type': 'Detailed Result Type (x-edge-detailed-result-type)',
	'sc-content-type': 'Content Type (sc-content-type)',
	'sc-content-len': 'Content Length (sc-content-len)',
} as const;

type Fields = keyof typeof FIELDS;

type Filter = {
	[K in Fields]: string;
};

function AccessLogs( props: Props ) {
	const [ filter, setFilter ] = useState<Filter>( {} as Filter );
	const [ format, setFormat ] = useState<'json' | 'csv'>( 'json' );

	const onSubmit = async ( e: React.FormEvent ) => {
		e.preventDefault();

		const url = defaultAPI.url + '/stack/applications/' + props.application.id + '/logs/access';
		const params: {[s: string]: any} = {
			after: props.after,
			before: props.before,
			format,
			download: true,
		};

		if ( defaultAPI.credentials.token?.public ) {
			params.access_token = defaultAPI.credentials.token.public;
		} else if ( defaultAPI instanceof cookieAuthApi && defaultAPI.nonce ) {
			params._wpnonce = defaultAPI.nonce;
		}

		// Remove any empty filters.
		const populatedFilters = { ...filter };
		Object.entries( populatedFilters ).forEach( ( [ key, value ] ) => {
			if ( ! value ) {
				delete populatedFilters[ key as keyof Filter ];
			}
		} );

		if ( Object.keys( populatedFilters ).length ) {
			params.filter = populatedFilters;
		}

		window.open( url + '?' + qs.stringify( params, { encode: true } ) );
	};

	const onChangeDuration = ( timeFilter: TimeFilter ) => {
		let after: string, before: string;

		// todo: support duration natively in the API
		if ( ( timeFilter as DurationFilter ).duration ) {
			const dur = ( timeFilter as DurationFilter ).duration;
			after = dur > 3600 ? `${ dur / 3600 } hours ago` : `${ dur / 60 } mins ago`;
			before = 'now';
		} else {
			after = ( timeFilter as ManualTimeFilter ).after || '';
			before = ( timeFilter as ManualTimeFilter ).before || '';
		}
		props.onTimeUpdate( {
			before,
			after,
		} );
	};

	return (
		<div className="ApplicationLogs-Access">
			<label className="flex items-center gap-4 mb-4">
				<span className="text-sm font-medium text-gray-700 w-64">Date</span>
				<DurationSelector
					flip
					selected={ {
						after: props.after || '6 hours ago',
						before: props.before || 'now',
					} }
					onSelect={ onChangeDuration }
				/>
			</label>

			<label className="flex items-center gap-4 mb-4">
				<span className="text-sm font-medium text-gray-700 w-64">Format</span>
				<select className="rounded-md border-gray-300 shadow-sm w-24 sm:text-sm" value={ format } onChange={ e => setFormat( e.target.value as 'json' | 'csv' ) }>
					<option value="json">JSON</option>
					<option value="csv">CSV</option>
				</select>
			</label>

			<form onSubmit={ onSubmit }>
				{ Object.entries( filter ).map( ( [ field, value ] ) => (
					<label key={ field } className="flex items-center gap-4 mb-4">
						<span className="text-sm font-medium text-gray-700 w-64">{ FIELDS[ field as Fields ] }</span>
						<span>=</span>
						<input
							className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
							type="text"
							value={ value || '' }
							onChange={ e =>
								setFilter( {
									...filter,
									[field]: e.target.value,
								} ) }
						/>
						<Button
							variant="destructive"
							onClick={ () => {
								const newFilter = { ...filter };
								delete newFilter[field as keyof Filter];
								setFilter( newFilter );
							} }
						>
							Delete
						</Button>
					</label>
				) ) }
				<div className="flex gap-2">
					<label>
						<span className="mr-4">Add filter</span>
						<select
							className="rounded-md border-gray-300 shadow-sm w-24 sm:text-sm"
							value=""
							onChange={ e => {
								if ( e.target.value ) {
									setFilter( {
										...filter,
										[e.target.value]: '',
									} );
									e.target.value = '';
								}
							} }
						>
							<option value="">Select field...</option>
							{ Object.entries( FIELDS ).map( ( [ field, label ] ) => (
								<option key={ field } value={ field }>
									{ label }
								</option>
							) ) }
						</select>
					</label>
				</div>
				<p className="text-gray-600 my-2">Filters are case-sensitive exact matches, and are joined with AND.</p>

				<p className="mt-4">
					<Button type="submit" variant="primary">
						Download Logs
					</Button>
				</p>
			</form>
		</div>
	);
}

export default withApiData<{}, Props>( _ => ( {} ) )( AccessLogs );
