import React from 'react';
import CpuIcon from 'react-feather/dist/icons/cpu';
import GlobeIcon from 'react-feather/dist/icons/globe';
import LockIcon from 'react-feather/dist/icons/lock';
import PackageIcon from 'react-feather/dist/icons/package';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ApplicationResponse } from '../../api';
import { canAdministrateApplication, canDevelopApplication } from '../../lib/utils';
import { AppState } from '../../reducers';
import SettingsPanel, { Page } from '../SettingsPanel';

import Deployment from './Deployment';
import Domains from './Domains';
import Environment from './Environment';
import Variables from './Variables';

interface Props {
	application: ApplicationResponse,
}

export default function ApplicationSettings( props: Props & RouteComponentProps ) {
	const { application } = props;
	const currentUser = useSelector( ( state: AppState ) => state.currentUser );
	const root = props.match.url;

	if ( ! currentUser ) {
		return 'Loading...';
	}

	const subpages: Page[] = [];

	if ( canAdministrateApplication( currentUser, application ) ) {
		subpages.push( {
			title: 'Deployment',
			icon: PackageIcon,
			path: `${ root }/deployment`,
			component: () => (
				<Deployment
					application={ application }
				/>
			),
		} );
		subpages.push( {
			title: 'Domains',
			icon: GlobeIcon,
			path: `${ root }/domains`,
			component: () => (
				<Domains
					application={ application }
				/>
			),
		} );

		subpages.push( {
			title: 'Environment',
			icon: CpuIcon,
			path: `${ root }/environment`,
			component: () => (
				<Environment
					application={ application }
				/>
			),
		} );
	}

	if ( application.flags.secrets && canDevelopApplication( currentUser, application ) ) {
		subpages.push( {
			title: 'Variables & Secrets',
			icon: LockIcon,
			path: `${ root }/variables`,
			route: `${ root }/variables/:type?`,
			component: props => (
				<Variables
					application={ application }
					type={ props.match.params.type || 'app' }
				/>
			),
		} );
	}

	return (
		<SettingsPanel
			defaultPath={ subpages[0]?.path }
			pages={ subpages.filter( Boolean ) as Page[] }
		/>
	);
}
