import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { sortLogItems } from '../lib/utils';
import { WithApiData } from '../lib/with-api-data';

import ApplicationLogsWarning from './ApplicationLogsWarning';
import FilterLogs from './FilterLogs';
import ElasticSearchLogItem from './PHPLogItem';

import './CronLogs.css';

class ElasticSearchLogs extends Component {
	static propTypes = {
		application: PropTypes.object.isRequired,
		logType: PropTypes.oneOf( [ 'search', 'index', 'error' ] ),
	};

	constructor( props ) {
		super( props );
		this.state = {
			after: this.props.after || '4 hours ago',
			before: this.props.before || 'now',
			search: '',
			type: this.props.logType,
		};
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( this.state );
		}
	}

	onChangeFilter( filter ) {
		this.apiData.invalidateData();
		this.setState( { ...filter } );
		if ( this.props.onTimeUpdate ) {
			this.props.onTimeUpdate( filter );
		}
	}

	render() {
		return (
			<div className="PHPLog">
				<FilterLogs { ...this.state } onChangeFilter={ filter => this.onChangeFilter( filter ) } />
				<WithApiData
					ref={ apiData => ( this.apiData = apiData ) }
					data={ {
						logs: `/stack/applications/${this.props.application.id}/logs/elasticsearch/${this.props.logType}?after=${this.state.after}&before=${this.state.before}&search=${this.state.search}`,
					} }
					render={ props => {
						return (
							<ApplicationLogsWarning
								logs={ props.logs }
								renderLogs={ logs =>
									logs
										.slice()
										.sort( sortLogItems )
										.map( log => <ElasticSearchLogItem key={ log.id } date={ new Date( log.date ) } { ...log } /> ) }
							/>
						);
					} }
					{ ...this.props }
				/>
			</div>
		);
	}
}

export default ElasticSearchLogs;
