import isFunction from 'lodash/isFunction';
import qs from 'qs';
import React from 'react';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';

import { ApplicationResponse } from '../../api';
import CronLogs from '../ApplicationLogsCron';
import CronRawLogs from '../ApplicationLogsCronRaw';
import ElasticSearchLogs from '../ApplicationLogsElasticSearch';
import LiveLineChart from '../LiveLineChart';
import OutlineButton from '../OutlineButton';
import PageTitle from '../PageTitle';

import AccessLogs from './Access';
import DatabaseErrors from './DatabaseErrors';
import DatabaseGeneral from './DatabaseGeneral';
import DatabaseSlow from './DatabaseSlow';
import EmailLogs from './Email';
import NginxLogs from './Nginx';
import NodejsLogs from './Nodejs';
import PHPLogs from './PHP';
import { TimeProps } from './util';

type LogGraphProps = {
	maximum: number,
	endpoint: string,
	period?: number,
}
function LogGraph( props: LogGraphProps ) {
	return (
		<LiveLineChart
			domain={ {
				y: [ 0, props.maximum ],
				x: [ new Date( new Date().getTime() - ( props.period || 1 ) * 60 * 60 * 1000 ), new Date().getTime() ],
			} }
			endpoint={ props.endpoint }
			fadeOut={ false }
			height={ 60 }
			nullIsZero
			unit=" Logs"
		/>
	);
}

type Tabs = {
	[ id: string ]: {
		available?: boolean | ( ( application: ApplicationResponse ) => boolean ),
		component: React.ComponentType<TimeProps & {
			application: ApplicationResponse,
			onTimeUpdate( t: any ): void,
		}>,
		title: string,
		graph?: React.ComponentType<{
			base: string,
			application: ApplicationResponse,
		}>,
	},
}

const allTheTabs: Tabs = {
	'php': {
		title: 'PHP',
		component: PHPLogs,
		graph: props => (
			<LogGraph
				endpoint={ `${ props.base }/${ props.application.id }?name=PHP-Warnings&statistic=Sum` }
				maximum={ 20 }
			/>
		),
	},
	'nginx': {
		title: 'Nginx',
		component: NginxLogs,
		graph: props => (
			<LogGraph
				endpoint={ `${ props.base }/AWS/Logs?name=IncomingLogEvents&dimensions[LogGroupName]=${ props.application.id }/nginx&statistic=Sum` }
				maximum={ 20 }
			/>
		),
	},
	'email': {
		title: 'Email',
		component: EmailLogs,
		graph: props => (
			<LogGraph
				endpoint={ `${ props.base }/AWS/Logs?name=IncomingLogEvents&dimensions[LogGroupName]=${ props.application.id }/ses&statistic=Sum&from=1 day ago&period=600` }
				maximum={ 50 }
				period={ 24 }
			/>
		),
	},
	'cron': {
		title: 'Cron',
		component: props => (
			<CronLogs
				{ ...props }
				application={ props.application.id }
			/>
		),
		graph: props => (
			<LogGraph
				endpoint={ `${ props.base }/Cavalcade?name=Invocations&dimensions[Application]=${ props.application.id }&statistic=Sum&from=1 day ago&period=600` }
				maximum={ 35 }
				period={ 24 }
			/>
		),
	},
	'cron-raw': {
		title: 'Cron Raw',
		component: props => (
			<CronRawLogs
				{ ...props }
				application={ props.application.id }
			/>
		),
	},
	'db-slowquery': {
		title: 'Slow Queries',
		component: DatabaseSlow,
	},
	'db-general': {
		title: 'MySQL Queries',
		component: DatabaseGeneral,
		available: application => application.has.query_log,
	},
	'db-error': {
		title: 'MySQL Errors',
		component: DatabaseErrors,
	},

	// Elasticsearch logs.
	'es-search': {
		title: 'ElasticSearch Slow Queries',
		component: props => (
			<ElasticSearchLogs
				{ ...props }
				logType="search"
			/>
		),
		available: application => application.has.elasticsearch,
	},
	'es-index': {
		title: 'ElasticSearch Slow Indexing',
		component: props => (
			<ElasticSearchLogs
				{ ...props }
				logType="index"
			/>
		),
		available: application => application.has.elasticsearch,
	},
	'es-error': {
		title: 'ElasticSearch Errors',
		component: props => (
			<ElasticSearchLogs
				{ ...props }
				logType="error"
			/>
		),
		available: application => application.has.elasticsearch,
	},

	// Node.js logs.
	'nodejs': {
		title: 'Node.js',
		component: props => (
			<NodejsLogs
				{ ...props }
				application={ props.application }
			/>
		),
		available: application => !! application.has.nodejs,
	},

	'access': {
		title: 'Access Logs',
		component: props => (
			<AccessLogs
				{ ...props }
				application={ props.application }
			/>
		),
		available: true,
	},
};
// @todo switch to `satisfies` with upgraded TS
type TabName = string;

interface OwnProps {
	application: ApplicationResponse,
}

type Props = OwnProps & RouteComponentProps<{}>;

function useQuery() {
	const location = useLocation();
	const history = useHistory();

	const parsed = qs.parse( location.search.substring( 1 ) );
	const params = {
		tab: typeof parsed.tab === 'string' ? parsed.tab : undefined,
		before: typeof parsed.before === 'string' ? parsed.before : '',
		after: typeof parsed.after === 'string' ? parsed.after : '',
	};
	return {
		params,
		setTab( tab: TabName | null | undefined ) {
			if ( tab ) {
				history.push( '?' + qs.stringify( { tab } ) );
			} else {
				history.push( location.pathname );
			}
		},
		setTimeFilter( args: TimeProps ) {
			history.push( '?' + qs.stringify( {
				tab: params.tab,
				before: args.before,
				after: args.after,
			} ) );
		},
	};
}

type TabLinkProps = {
	application: ApplicationResponse,
	id: string,
	metricsBase: string,
	tab: Tabs[''],
	onSelect(): void,
}

function TabLink( props: TabLinkProps ) {
	const { tab } = props;
	if ( Object.prototype.hasOwnProperty.call( tab, 'available' ) ) {
		const available = isFunction( tab.available ) ? tab.available( props.application ) : tab.available;
		if ( ! available ) {
			return null;
		}
	}

	let Graph = undefined;
	if ( props.metricsBase && Object.prototype.hasOwnProperty.call( tab, 'graph' ) ) {
		Graph = tab.graph!;
	}

	return (
		<li
			className="overflow-hidden rounded-xl bg-gray-50 border border-gray-200 flex flex-col justify-between"
			onSelect={ props.onSelect }
		>
			<button
				className="group flex items-center text-left cursor-pointer"
				type="button"
				onClick={ props.onSelect }
			>
				<div className="grow shrink-0 px-6 py-6 bg-gray-50 flex items-center text-blue-600 group-hover:text-blue-900">
					{ tab.title }

					<ChevronRightIcon
						className="h-4 w-4 translate-x-0 transition-transform group-hover:translate-x-1"
					/>
				</div>
				{ Graph && (
					<div className="shrink-0 min-w-[150px] -mt-2 divide-y divide-gray-100 px-6 py-2 text-sm leading-6">
						<Graph
							application={ props.application }
							base={ props.metricsBase }
						/>
					</div>
				) }
			</button>
		</li>
	);
}

export default function ApplicationLogs( props: Props ) {
	const { application } = props;
	const { params, setTab, setTimeFilter } = useQuery();
	const { tab, before, after } = params;

	if ( ! application ) {
		return null;
	}

	const metricsBase = application._links?.self[0].href.replace( '/applications/', '/metrics/' ) || '';
	if ( ! tab ) {
		return (
			<div className="ApplicationLogs">
				<PageTitle title="Logs" />

				<ul className="my-4 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-8 xl:grid-cols-3">
					{ ( Object.keys( allTheTabs ) as TabName[] ).map( id => (
						<TabLink
							key={ id }
							application={ application }
							id={ id }
							metricsBase={ metricsBase }
							tab={ allTheTabs[ id ] }
							onSelect={ () => setTab( id ) }
						/>
					) ) }
				</ul>
			</div>
		);
	}

	const activeTab = allTheTabs[ tab ];
	if ( ! activeTab ) {
		return (
			<div className="ApplicationLogs">
				<PageTitle title="Logs" />

				<header className="my-4 grid grid-cols-[auto_max-content] items-center border-b border-gray-200">
					<h2>Not Found</h2>

					<OutlineButton
						name="Switch Log Type"
						onClick={ () => setTab( null ) }
					/>
				</header>
			</div>
		);
	}

	const Component = activeTab.component;

	return (
		<div className="ApplicationLogs">
			<PageTitle title="Logs" />
			<PageTitle title={ activeTab.title } />

			<header className="my-4 grid grid-cols-[auto_max-content] items-center border-b border-gray-200">
				<h2>{ activeTab.title }</h2>

				<OutlineButton
					name="Switch Log Type"
					onClick={ () => setTab( null ) }
				/>
			</header>

			<Component
				after={ after }
				application={ application }
				before={ before }
				onTimeUpdate={ setTimeFilter }
			/>
		</div>
	);
}
