import React, { Component } from 'react';
import { connect } from 'react-redux';

import { defaultAPI } from '../api';
import { withApiData } from '../lib/with-api-data';

import ApplicationHeader from './ApplicationHeader';
import ApplicationServersScaling from './ApplicationServersScaling';
import DatabaseServersList from './DatabaseServersList';
import ElasticSearchClusterListItem from './ElasticSearchClusterListItem';
import ObjectCacheServersList from './ObjectCacheServersList';
import PageTitle from './PageTitle';
import PillButton from './PillButton';
import WebServersList from './WebServersList';

import './ApplicationServers.css';

class ApplicationServers extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			isFlushingObjectCacheServer: false,
		};
	}
	componentDidMount() {
		this.interval = setInterval( () => this.props.refreshData(), 30 * 1000 );
	}
	componentWillUnmount() {
		clearInterval( this.interval );
	}
	onPinWebServer( webServer ) {
		this.props
			.post( `/stack/applications/${this.props.application.id}/web-servers/${webServer.id}`, {
				'scale-in-protection': true,
			} )
			.then( () => this.props.refreshData() );
	}
	onUnpinWebServer( webServer ) {
		this.props
			.post( `/stack/applications/${this.props.application.id}/web-servers/${webServer.id}`, {
				'scale-in-protection': false,
			} )
			.then( () => this.props.refreshData() );
	}
	render() {
		return (
			<>
				<PageTitle title="Servers" />
				{ this.props.application && (
					<ApplicationHeader
						application={ this.props.application }
						title="Servers"
					/>
				) }
				<div className="ApplicationServers">
					<h3>Webservers</h3>
					<ApplicationServersScaling
						application={ this.props.application.id }
					/>
					<WebServersList
						isLoading={ this.props.webServers.isLoading }
						items={ this.props.webServers.data || [] }
						showSSHConnection={ this.props.currentUser.capabilities.administrator }
						onPin={ this.props.currentUser.capabilities.edit_applications ? webServer => this.onPinWebServer( webServer ) : null }
						onUnpin={ webServer => this.onUnpinWebServer( webServer ) }
					/>
					<h3>Sandbox Server</h3>
					<WebServersList
						isLoading={ this.props.sandboxServer.isLoading }
						items={ this.props.sandboxServer.data ? [ this.props.sandboxServer.data ] : [] }
						showSSHConnection={ this.props.currentUser.capabilities.ssh_applications_sandbox }
					/>
					<div className="database-server-header">
						<h3>Database Servers</h3>
						{ this.props.onOpenSequelPro ? (
							<PillButton
								name="Open in Sequel Pro"
								onClick={ () => this.props.onOpenSequelPro( this.props.databaseServer.data ) }
							/>
						) : null }
					</div>
					<DatabaseServersList
						isLoading={ this.props.databaseServer.isLoading }
						items={ this.props.databaseServer.data ? [ this.props.databaseServer.data ] : [] }
					/>
					<div className="database-server-header">
						<h3>Object Cache Server</h3>
					</div>
					<ObjectCacheServersList application={ this.props.application.id } />

					{ this.props.elasticSearchCluster.data && this.props.elasticSearchCluster.data.id && (
						<div>
							<div className="database-server-header">
								<h3>ElasticSearch Cluster</h3>
							</div>
							<ElasticSearchClusterListItem { ...this.props.elasticSearchCluster.data } />
						</div>
					) }
				</div>
			</>
		);
	}
}

const ApplicationServersWithApiData = withApiData( props => {
	return {
		webServers: `/stack/applications/${ props.application.id }/web-servers`,
		databaseServer: `/stack/applications/${ props.application.id }/database-server`,
		elasticSearchCluster: `/stack/applications/${ props.application.id }/elasticsearch-cluster`,
		sandboxServer: `/stack/applications/${ props.application.id }/sandbox-server`,
	};
} )( ApplicationServers );

const mapStateToProps = state => ( {
	currentUser: state.currentUser,
} );

const mergeProps = ( stateProps, dispatchProps, ownProps ) => {
	const props = {
		...stateProps,
		...ownProps,
		dispatch: dispatchProps.dispatch,
	};

	if ( stateProps.currentUser.capabilities.edit_applications ) {
		props.onOpenSequelPro = databaseServer => {
			window.open(
				`${databaseServer['sequelpro-file']}?access_token=${
					defaultAPI.credentials &&
					defaultAPI.credentials.token &&
					defaultAPI.credentials.token.public}`
			);
		};
	}

	return props;
};

export default connect(
	mapStateToProps,
	null,
	mergeProps
)( ApplicationServersWithApiData );
