import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import AlertTriangle from 'react-feather/dist/icons/alert-triangle';
import ChevronDownIcon from 'react-feather/dist/icons/chevron-down';
import ChevronRightIcon from 'react-feather/dist/icons/chevron-right';
import { Link } from 'react-router-dom';

import { ApplicationResponse } from '../../api';
import Button from '../Button';
import CopiableValue from '../CopiableValue';
import InlineLoader from '../InlineLoader';

import { Domain } from './Domains';
import DomainSteps, { Step } from './DomainSteps';

const STATUS_CLASS = {
	active: 'bg-green-100 text-green-800',
	pending: 'bg-[#e9ebed]',
	failed: 'bg-[#c72a1c] text-white',
	suspended: 'bg-[#ffb648] text-[#703b15]',
};
const STATUS_NAME = {
	active: 'Active',
	pending: 'Pending',
	failed: 'Failed',
	suspended: 'Suspended',
};

const DOMAIN_ORIGIN_NAME = {
	nodejs: 'Node.js',
	php: 'PHP',
};

const DOMAIN_ORIGIN_CLASS = {
	nodejs: 'bg-gray-700 text-gray-300',
	php: 'bg-indigo-300 text-indigo-900',
};

type RecordsProps = {
	records: Domain['records'],
}

export function Records( props: RecordsProps ) {
	const [ didCopy, setDidCopy ] = useState<boolean>( false );
	const { records } = props;
	if ( ! records ) {
		return null;
	}

	const recordsText = records.map( record => `${ record.name } IN ${ record.type } ${ record.value }` ).join( '\n' ) + '\n';

	return (
		<div className="w-full">
			<div className="w-full overflow-x-auto mb-2">
				<table className="w-full overflow-x-auto divide-y divide-gray-300">
					<thead>
						<tr>
							<th
								className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
								scope="col"
							>
								Type
							</th>
							<th
								className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
								scope="col"
							>
								Name
							</th>
							<th
								className="px-1 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
								scope="col"
							>
								Value
							</th>
						</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 bg-white">
						{ records.map( record => (
							<tr key={ record.name }>
								<td className="px-1 py-3 text-xs whitespace-nowrap">
									<CopiableValue
										className="whitespace-nowrap text-ellipsis"
										value={ record.type }
									/>
								</td>
								<td className="px-1 py-3 text-xs whitespace-nowrap text-ellipsis">
									<CopiableValue
										className="whitespace-nowrap text-ellipsis"
										value={ record.name }
									/>
								</td>
								<td className="px-1 py-3 text-xs whitespace-nowrap text-ellipsis">
									<CopiableValue
										className="whitespace-nowrap text-ellipsis"
										value={ record.value }
									/>
								</td>
							</tr>
						) ) }
					</tbody>
				</table>
			</div>
			<CopyToClipboard
				text={ recordsText }
				onCopy={ () => setDidCopy( true ) }
			>
				<Button>
					{ didCopy ? 'Copied!' : 'Copy as zonefile' }
				</Button>
			</CopyToClipboard>
		</div>
	);
}

type MainRecordProps = {
	domain: Domain,
	internalDomain: string,
}
function MainRecord( props: MainRecordProps ) {
	const { domain, internalDomain } = props;
	const [ tab, setTab ] = useState<'cname' | 'alias'>( 'cname' );
	const activeTabClass = 'border-indigo-500 text-indigo-600';
	const inactiveTabClass = 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700';
	return (
		<div>
			<div className="border-b border-gray-200">
				<nav
					aria-label="Tabs"
					className="-mb-px flex space-x-8"
				>
					<button
						aria-current={ tab === 'cname' }
						className={ [
							tab === 'cname' ? activeTabClass : inactiveTabClass,
							'whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium',
						].filter( Boolean ).join( ' ' ) }
						type="button"
						onClick={ () => setTab( 'cname' ) }
					>
						CNAME
					</button>
					<button
						aria-current={ tab === 'alias' }
						className={ [
							tab === 'alias' ? activeTabClass : inactiveTabClass,
							'whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium',
						].filter( Boolean ).join( ' ' ) }
						type="button"
						onClick={ () => setTab( 'alias' ) }
					>
						ALIAS
					</button>
				</nav>
			</div>
			{ tab === 'cname' ? (
				<div>
					<p className="text-sm text-gray-500 my-2">
						Use CNAME records for subdomains (non-apex domains).
						Root/apex domains must use ALIAS records instead.
					</p>
					<Records
						records={ [
							{
								name: `${ domain.name }.`,
								type: 'CNAME',
								value: `${ internalDomain }.`,
							},
						] }
					/>
				</div>
			) : (
				<div>
					<p className="text-sm text-gray-500 my-2">
						Use ALIAS records for root/apex domains. Your DNS
						provider may call these ANAME records.
					</p>
					<p className="text-sm text-gray-500 my-2">
						(If you're unable to use ALIAS records, <Link to="/support/new">contact Altis
						support</Link> for information about alternative solutions.)
					</p>
					<Records
						records={ [
							{
								name: `${ domain.name }.`,
								type: 'ALIAS',
								value: `${ internalDomain }.`,
							},
						] }
					/>
				</div>
			) }
		</div>
	);
}

type DomainRowProps = {
	application: ApplicationResponse,
	defaultOpen?: boolean,
	domain: Domain,
	showSteps?: boolean,
}

export default function DomainRow( props: DomainRowProps ) {
	const [ open, setOpen ] = useState<boolean>( props.defaultOpen || false );
	const showSteps = props.showSteps === true || props.showSteps === undefined;
	const internalDomainMapping: { [key: string]: string } = {
		'nodejs': props.application.internal_nodejs_domain,
		'php': props.application.internal_domain,
	};

	const { application, domain } = props;
	return (
		<li className="block border border-gray-200 rounded-lg shadow-sm">
			<div className="px-4 pt-5 last:pb-5">
				<div className="flex items-center justify-between">
					<p className="truncate text-sm font-medium text-blue-600">{ domain.name }</p>
					<div className="ml-2 font-semibold flex flex-shrink-0">
						<p
							className={ `mr-2 rounded-full px-2 text-xs font-semibold leading-5 ${ DOMAIN_ORIGIN_CLASS[ domain.origin as keyof typeof DOMAIN_ORIGIN_CLASS ] || '' }` }
						>
							{ DOMAIN_ORIGIN_NAME[ domain.origin ] }
						</p>
						<p
							className={ `inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${ STATUS_CLASS[ domain.status as keyof typeof STATUS_CLASS ] || '' }` }
						>
							{ STATUS_NAME[ domain.status ] || domain.status }
						</p>
					</div>
				</div>
				<div className="my-2 sm:flex sm:justify-between">
					<div className="sm:flex divide-x">
						{ domain.name === application.internal_domain && (
							<p className="px-2 first:pl-0 flex items-center text-sm text-gray-500">
								Internal Domain
							</p>
						) }
						{ domain.name === application.internal_nodejs_domain && (
							<p className="px-2 first:pl-0 flex items-center text-sm text-gray-500">
								Internal Node.js Domain
							</p>
						) }
						{ domain.status === 'pending' && (
							<p className="px-2 first:pl-0 flex items-center text-sm text-red-600">
								<AlertTriangle
									className="mr-1"
									size={ 14 }
								/>
								Validation pending
							</p>
						) }
						{ domain.name.includes( '*' ) && (
							<p className="px-2 first:pl-0 flex items-center text-sm text-gray-500">
								Wildcard (* matches all subdomains)
							</p>
						) }
					</div>
				</div>
			</div>
			{ ( domain.records && domain.status !== 'failed' ) && (
				domain.records.length > 0 ? (
					<details
						className="bg-gray-50 group p-4"
						open={ open }
						onChange={ e => setOpen( ( e.target as HTMLDetailsElement ).open ) }
					>
						<summary className="text-sm cursor-pointer marker:hidden marker:content-none">
							<ChevronDownIcon className="hidden group-open:inline-block" size={ 15 } />
							<ChevronRightIcon className="inline-block group-open:hidden" size={ 15 } />
							View details
						</summary>

						{ showSteps && (
							<div className="my-3">
								<DomainSteps
									current={ domain.status === 'pending' ? Step.verification : Step.switchover }
								/>
							</div>
						) }

						{ domain.status === 'active' && (
							<>
								<p className="text-sm text-gray-500 my-2">
									Your domain has been successfully validated.
									When you're ready to launch, switch your domain to point to your Altis environment.
								</p>

								<MainRecord
									domain={ domain }
									internalDomain={ internalDomainMapping[domain.origin] || props.application.internal_domain! }
								/>

								<p className="text-sm text-gray-500 mt-4 mb-2">
									Ensure you keep your validation records in place. If you need them again, they are:
								</p>
							</>
						) }

						{ domain.status === 'pending' && (
							<p className="text-sm text-gray-500 my-2">
								Your domain is pending validation.
								Set the following records on your DNS provider to continue:
							</p>
						) }

						<Records
							records={ domain.records }
						/>
					</details>
				) : (
					<div className="group bg-gray-50 p-4">
						<p className="text-sm text-gray-500 my-2">
							<InlineLoader className="-ml-1 mr-3 h-5 w-5" />

							Verification records are being generated. This may take a few minutes, please wait.
						</p>
					</div>
				)
			) }
		</li>
	);
}
