import qs from 'qs';
import wordressAPI from 'wordpress-rest-api-oauth-2';

let oauth2AppId = process.env.NODE_ENV === 'production' ? 'dwvpz7glqlvh' : 'yb1cadg6j4nw';
let oauth2AppCallbackURL =
	process.env.NODE_ENV === 'production'
		? `https://vantage.aws.hmn.md${window.location.pathname}`
		: 'http://localhost:3000/';

if ( window.location.host === 'dashboard.altis-dxp.com' ) {
	oauth2AppId = '0sny8ue1qbgz';
	oauth2AppCallbackURL = 'https://dashboard.altis-dxp.com/';
}

if ( window.location.host === 'dashboard.humansmadeus.com' ) {
	oauth2AppId = 'm9tovjgn0d1n';
	oauth2AppCallbackURL = 'https://dashboard.humansmadeus.com/';
}

if ( process.env.REACT_APP_OAUTH_ID ) {
	oauth2AppId = process.env.REACT_APP_OAUTH_ID;
}

export class RestApiError extends Error {
	code: string;
	data: object;
	errors: {
		[key: string]: RestApiError
	};
	constructor( message: string, code: string, data: object, errors: {} ) {
		super( message );
		this.code = code;
		this.data = data;
		this.errors = errors;
	}
}

export const parseResponse = ( resp: { ok: boolean, json: () => Promise<any>, headers?: any } ) => resp.json().then( data => {
	if ( resp.ok ) {
		// Expose response via a getter, which avoids copying.
		Object.defineProperty( data, 'getResponse', {
			get: () => () => resp,
		} );
		return data;
	}

	// Build an error
	const errors: { [key: string]: object } = {};

	errors[ data.code ] = {
		data: data.data,
		message: data.message,
		code: data.code,
	};

	if ( data.additional_errors ) {
		data.additional_errors.forEach( ( error: { code: string } ) => {
			errors[ error.code ] = error;
		} );
	}

	const err = new RestApiError( data.message, data.code, data, errors );

	throw err;
} );

export class delegatedOauthApi extends wordressAPI {
	request( method: string, url: string, data: any = null ) {
		if ( url.indexOf( 'http' ) !== 0 ) {
			url = this.url + url;
		}

		if ( method === 'GET' && data ) {
			url += `?${decodeURIComponent( qs.stringify( data ) )}`;
			data = null;
		}

		let headers: {
			[s: string]: string;
		} = {
			Accept: 'application/json',
		};

		if ( method !== 'GET' && method !== 'HEAD' && data ) {
			headers['Content-Type'] = 'application/x-www-form-urlencoded';
		}

		/**
		 * Only attach the oauth headers if we have a request token
		 */
		if ( this.credentials.token ) {
			headers = {
				...headers,
				...this.getAuthorizationHeader(),
			};
		}

		const opts: RequestInit = {
			method,
			headers,
			mode: 'cors',
			body: [ 'GET', 'HEAD' ].indexOf( method ) > -1 ? null : qs.stringify( data ),
		};

		return fetch( url, opts ).then( response => {
			return response.text().then( responseText => {
				let json: any;
				try {
					json = JSON.parse( responseText );
				} catch ( e ) {
					const err: any = new Error( responseText );
					err.code = response.status;
					throw err;
				}
				return parseResponse( {
					ok: response.ok,
					json: () => Promise.resolve( json ),
					headers: response.headers,
				} );
			} );
		} );
	}
	fetch( url: string, options?: any ) {
		// Make URL absolute
		const relUrl = url[0] === '/' ? url.substring( 1 ) : url;
		const absUrl = new URL( relUrl, this.url + '/' );

		// Clone options
		const actualOptions = {
			headers: {},
			...options,
		};

		/**
		 * Only attach the oauth headers if we have a request token
		 */
		if ( this.credentials.token ) {
			actualOptions.headers = {
				...actualOptions.headers,
				...this.getAuthorizationHeader(),
			};
		}

		return fetch( String( absUrl ), actualOptions );
	}
}

export class cookieAuthApi extends wordressAPI {
	nonce?: string;
	constructor( options: { rest_url?: string; nonce: string } ) {
		super( {
			credentials: {
				client: {
					id: 'none',
				},
			},
			rest_url: options.rest_url,
		} );
		this.nonce = options.nonce;
	}
	request( method: string, url: string, data: any = null ) {
		if ( url.indexOf( 'http' ) !== 0 ) {
			url = this.url + url;
		}

		if ( [ 'GET', 'DELETE', 'HEAD' ].indexOf( method ) > -1 && data ) {
			url += `${ url.indexOf( '?' ) > -1 ? '&' : '?' }${decodeURIComponent( qs.stringify( data ) )}`;
			data = null;
		}

		let headers: {
			[s: string]: string;
		} = {
			Accept: 'application/json',
		};

		if ( this.nonce ) {
			headers['X-WP-Nonce'] = this.nonce;
		}

		if ( method !== 'GET' && method !== 'HEAD' && data ) {
			headers['Content-Type'] = 'application/x-www-form-urlencoded';
		}

		const opts: RequestInit = {
			method,
			headers,
			credentials: 'same-origin',
			body: [ 'GET', 'HEAD' ].indexOf( method ) > -1 ? null : qs.stringify( data ),
		};

		return fetch( url, opts ).then( response => {
			return response.text().then( responseText => {
				let json: any;
				try {
					json = JSON.parse( responseText );
				} catch ( e ) {
					const err: any = new Error( responseText );
					err.code = response.status;
					throw err;
				}
				return parseResponse( {
					ok: response.ok,
					json: () => Promise.resolve( json ),
					headers: response.headers,
				} );
			} );
		} );
	}
	fetch( url: string, options?: any ) {
		// Make URL absolute
		const relUrl = url[0] === '/' ? url.substring( 1 ) : url;
		const absUrl = new URL( relUrl, this.url + '/' );

		// Clone options
		const actualOptions = {
			headers: {},
			...options,
		};

		if ( this.nonce && ! actualOptions.headers['X-WP-Nonce'] ) {
			actualOptions.headers['X-WP-Nonce'] = this.nonce;
		}

		return fetch( String( absUrl ), actualOptions );
	}
	removeCredentials() : Promise<any> {
		return this.request( 'DELETE', '/sessions/v0/sessions/current' );
	}
}

const getAPI = ( host: string ): delegatedOauthApi|cookieAuthApi => {
	// Support for cookie auth via VantageInitData global.
	if ( window.VantageInitData ) {
		return new cookieAuthApi( {
			rest_url: window.VantageInitData.rest_url,
			nonce: window.VantageInitData.nonce,
		} );
	}

	switch ( host ) {
		case 'dashboard.humansmadeus.com': {
			return new delegatedOauthApi( {
				rest_url: 'https://test-eu-central-1.aws.hmn.md/api',
				credentials: {
					client: {
						id: oauth2AppId,
					},
				},
				callbackURL: oauth2AppCallbackURL,
			} );
		}

		case 'localhost:3000':
			// Use settings if configured in environment variables.
			if ( process.env.REACT_APP_LOCAL_STACK ) {
				return new delegatedOauthApi( {
					rest_url: process.env.REACT_APP_LOCAL_STACK + '/api',
					credentials: {
						client: {
							id: oauth2AppId,
						},
					},
					callbackURL: 'http://localhost:3000/',
				} );
			}

			// Fallthrough.

		default:
			return new delegatedOauthApi( {
				rest_url: 'https://eu-west-1.aws.hmn.md/api',
				credentials: {
					client: {
						id: oauth2AppId,
					},
				},
				callbackURL: oauth2AppCallbackURL,
			} );
	}
};

export const regions = [
	'eu-west-1',
	'eu-west-2',
	'us-east-1',
	'ca-central-1',
	'eu-central-1',
	'ap-southeast-1',
	'ap-southeast-2',
	'ap-northeast-1',
];

// export const defaultApi = getAPI( 'dashboard.humansmadeus.com' );
export const defaultAPI = getAPI( window.location.host );

window.api = defaultAPI;

export type API = delegatedOauthApi | cookieAuthApi;

export * from './types/rest-api';
