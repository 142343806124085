import qs from 'qs';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import './App.css';

import { updateCurrentUser } from './actions';
import { defaultAPI } from './api';
import AccelerateApp from './components/accelerate/App';
import ApplicationRedirecter from './components/ApplicationRedirecter';
import AppLoader from './components/AppLoader';
import Blog from './components/Blog';
import BlogSingle from './components/Blog/Single';
import EditProfileForm from './components/EditProfileForm';
import InstanceContainer from './components/InstanceContainer';
import Login from './components/Login';
import LoginOAuth from './components/LoginOAuth';
import NewHome from './components/NewHome';
import NonAppWrap from './components/NonAppWrap';
import Overview from './components/Overview';
import PersonalDataCollectionOptIn from './components/PersonalDataCollectionOptIn';
import Productboard from './components/Productboard';
import Region from './components/Region';
import SupportDashboard from './components/SupportDashboard';

class App extends Component {
	fetchRunningTasksIntervals = [];
	constructor( props ) {
		super( props );
		this.state = {
			checkedAuth: false,
			needsAuth: false,
			authError: null,
			hasOptedInToPersonalDataCollection: false,
			hasRespondedToPersonalDataCollection: false,
		};
	}

	componentDidMount() {
		if ( this.props.location.pathname.indexOf( '/accelerate/' ) !== -1 ) {
			// Switch off GoSquared chat on the embedded form.
			window.analytics.addDestinationMiddleware( 'gosquared', [ ( { payload, next } ) => {
				if ( this.props.location.pathname.indexOf( '/embedded' ) !== -1 ) {
					window._gs( 'set', 'chat', false );
				}
				next( payload );
			} ] );
			window.analytics.load( 'qUQDZEDMJNA49bRaqGxBQTmW7ywBf8EG' );
		} else {
			window.analytics.load( 'Mlt3u8jCPt3QaszADuJp3bMPqv2pMWwz' );
		}
		if ( window.Notification ) {
			window.Notification.requestPermission();
		}
		this.checkAuth();
		this.props.history.listen( location => {
			window.analytics.page( null, null, {
				path: location.pathname,
			} );
		} );
		window.analytics.page( null, null, {
			path: this.props.location.pathname,
		} );
	}

	componentWillUnmount() {
		this.fetchRunningTasksIntervals.forEach( interval => {
			window.clearInterval( interval );
		} );
	}

	checkAuth() {
		this.setState( {
			authError: null,
		} );

		// Check if the user is logged in with cookies.
		if ( window.VantageInitData?.logged_in ) {
			this.onLoggedIn();
			return;
		}

		// Don't use local storage when embeeded.
		if ( window.VantageInitData ) {
			this.onMissingAuth();
			return;
		}

		let savedCredentials = window.localStorage.getItem( 'requestTokenCredentials' );
		// Parse implicit token passed in fragment
		if ( savedCredentials ) {
			this.handleReceiveToken();
			return;
		}

		defaultAPI.restoreCredentials();
		if ( defaultAPI.hasCredentials() ) {
			this.onLoggedIn();
		} else {
			this.onMissingAuth();
		}
	}

	handleReceiveToken() {
		if ( ! window.location.href.indexOf( '#' ) ) {
			this.onMissingAuth();
			return;
		}

		const args = qs.parse( window.location.hash.substring( 2 ) );
		if ( ! args.access_token || args.code ) {
			this.onMissingAuth();
			return;
		}
		// Remove the hash if we can.
		if ( window.history.pushState ) {
			window.history.pushState( null, null, window.location.href.split( '#' )[0] );
		} else {
			window.location.hash = '';
		}

		window.localStorage.removeItem( 'requestTokenCredentials' );

		if ( args.code ) {
			defaultAPI.getAccessToken( args.code ).then( () => {
				defaultAPI.saveCredentials();
				this.onLoggedIn();
			} );
		}

		if ( ! args.access_token ) {
			this.onMissingAuth();
			return;
		}

		defaultAPI.credentials.token = {
			public: args.access_token,
		};
		defaultAPI.saveCredentials();
		this.onLoggedIn();
	}

	identifyUserForDataCollection() {
		const user = this.props.user;
		if ( ! user ) {
			return;
		}

		/**
		 * Taken from Stackoverflow https://stackoverflow.com/a/50030304
		 */
		async function sha1( str ) {
			const buffer = new TextEncoder( 'utf-8' ).encode( str );
			const digest = await crypto.subtle.digest( 'SHA-1', buffer );

			// Convert digest to hex string
			const result = Array.from( new Uint8Array( digest ) ).map( x => x.toString( 16 ).padStart( 2, '0' ) ).join( '' );
			return result;
		}
		sha1( user.email ).then( id => {
			window.analytics.identify(
				id,
				{
					first_name: user.first_name,
					last_name: user.last_name,
					email: user.email,
					avatar: user.avatar_urls['96'],
					username: user.slug,
				}
			);
		} );
	}

	async onLoggedIn() {
		this.props.dispatch( { type: 'CURRENT_USER_LOADING' } );
		let user = null;
		try {
			user = await defaultAPI.get( '/wp/v2/users/me?context=edit' );
			this.props.dispatch( {
				type: 'CURRENT_USER_UPDATED',
				payload: { user },
			} );
		} catch ( error ) {
			this.setState( {
				authError: error.message,
				needsAuth: true,
			} );
			return;
		}
		if ( user.has_opted_in_to_personal_data_collection ) {
			this.identifyUserForDataCollection();
		}
		this.setState( {
			checkedAuth: true,
			needsAuth: false,
		} );
	}

	onMissingAuth() {
		this.setState( {
			needsAuth: true,
		} );
	}

	onClickLogin() {
		defaultAPI.authorize().then( async () => {
			// We've apparently authorized but ended up here, so there's
			// either a transient connection error or our credentials are
			// invalid. Issue a user request to see what the deal is.
			this.props.dispatch( { type: 'CURRENT_USER_LOADING' } );
			try {
				const user = await defaultAPI.get( '/wp/v2/users/me?context=edit' );

				// If we got here, it was a transient connection error.
				this.props.dispatch( {
					type: 'CURRENT_USER_UPDATED',
					payload: { user },
				} );
			} catch ( error ) {
				// Error still occuring, and the user explicitly clicked
				// "Log In" rather than retry. Wipe their credentials, and
				// authorize again.
				defaultAPI.removeCredentials();
				this.props.dispatch( { type: 'CURRENT_USER_LOGOUT' } );
				defaultAPI.authorize();
			}
		} );
	}

	onEmbeddedLoggedIn( response ) {
		defaultAPI.nonce = response.nonce;
		this.onLoggedIn();
	}

	onClickLogout() {
		const removeRequest = defaultAPI.removeCredentials();
		this.props.dispatch( { type: 'CURRENT_USER_LOGOUT' } );
		this.setState( {
			checkedAuth: false,
			needsAuth: true,
		} );
		// If the app is embedded in WordPress, we need to reload to get a new auth_nonce to log back in again.
		if ( window.VantageInitData && removeRequest ) {
			removeRequest.then( () => {
				window.location.reload();
			} );
		}
	}

	onRespondToPersonalDataCollection = answer => {
		this.props.dispatch( updateCurrentUser( {
			has_opted_in_to_personal_data_collection: answer,
			has_responded_to_personal_data_collection: true,
		} ) );
		if ( answer ) {
			this.identifyUserForDataCollection();
		}
	};

	render() {
		return (
			<Switch>
				<Route path="/accelerate" render={ () => (
					<AccelerateApp
						onClickLogout={ () => this.onClickLogout() }
						onLoggedIn={ response => this.onEmbeddedLoggedIn( response ) }
					/>
				) } />
				<Route path="/" render={ () => {
					if ( this.state.needsAuth || this.state.authError ) {
						if ( ! window.VantageInitData ) {
							// In standalone mode, use OAuth.
							return (
								<LoginOAuth
									authError={ this.state.authError }
									onClickLogin={ () => this.onClickLogin() }
									onRetryLogin={ () => this.checkAuth() }
								/>
							);
						}

						// Embedded mode, use cookies.
						return (
							<Login
								onLogin={ r => this.onEmbeddedLoggedIn( r ) }
							/>
						);
					}

					if ( ! this.state.checkedAuth ) {
						return (
							<div className="App">
								<AppLoader />
							</div>
						);
					}

					return (
						<div className="App">
							<AppLoader />
							{ ! this.props.user.has_responded_to_personal_data_collection && (
								<PersonalDataCollectionOptIn
									onRespondToPersonalDataCollection={ this.onRespondToPersonalDataCollection }
								/>
							) }
							<Switch>
								<Route
									path="/i/:instance"
									render={ props => (
										<InstanceContainer
											{ ...props }
											onLogout={ () => this.onClickLogout() }
										/>
									) }
								/>
								<Route
									exact
									path="/"
									render={ props => {

										// Redirect hash URLs if we are using embedded in WordPress rendering.
										if ( window.VantageInitData && props.location.hash ) {
											return <Redirect to={ props.location.hash.replace( '#', '' ) } />;
										}

										return (
											<NewHome
												{ ...props }
												onLogout={ () => this.onClickLogout() }
											/>
										);
									} }

								/>
								<Route
									exact
									path="/:region(us-east-1|eu-west-1|eu-west-2|eu-central-1|ap-southeast-1|ap-southeast-2|ap-northeast-1)/(amis|logs|)"
									render={ props => (
										<NonAppWrap>
											<Region
												{ ...props }
											/>
										</NonAppWrap>
									) }
								/>
								<Route
									component={ ApplicationRedirecter }
									path="/e/:application/:subpath(.+)?"
								/>
								<Route
									component={ ApplicationRedirecter }
									path="/:region(us-east-1|eu-west-1|eu-west-2|eu-central-1|ap-southeast-1|ap-southeast-2|ap-northeast-1)/:application/:subpath(.+)?"
								/>
								<Route
									path="/overview"
									render={ props => (
										<NonAppWrap>
											<Overview
												{ ...props }
											/>
										</NonAppWrap>
									) }
								/>
								<Route
									path="/support"
									render={ props => (
										<NonAppWrap>
											<SupportDashboard
												{ ...props }
											/>
										</NonAppWrap>
									) }
								/>
								<Route
									exact
									path="/profile"
									render={ () => (
										<NonAppWrap>
											<EditProfileForm
												onLogOut={ () => this.onClickLogout() }
											/>
										</NonAppWrap>
									) }
								/>
								<Route
									exact
									path="/roadmap"
									render={ () => (
										<NonAppWrap>
											<Productboard />
										</NonAppWrap>
									) }
								/>
								<Route
									path="/blog/:id(\d+)/:article"
									render={ ( { match } ) => (
										<NonAppWrap>
											<BlogSingle
												postId={ match.params.id }
											/>
										</NonAppWrap>
									) }
								/>
								<Route
									path="/blog/:page(\d+)?"
									render={ () => (
										<NonAppWrap>
											<Blog />
										</NonAppWrap>
									) }
								/>
								<Route>
									<NonAppWrap>
										<h1>Not Found</h1>
										<p>Whoops, we can't find that URL.</p>
									</NonAppWrap>
								</Route>
							</Switch>
						</div>
					);
				} } />
			</Switch>
		);
	}
}

const mapStateToProps = ( state => ( {
	user: state.currentUser.user,
} ) );
export default withRouter( connect( mapStateToProps )( App ) );
